import * as React from "react"
// import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"


import { Container, Row, Col, Carousel } from "react-bootstrap"



const IndexPage = () => (
  <Layout>
    <Seo title="E-bike Hire, Private E-bike Tours, &amp; E-Tuk Tours - Rarotonga" />

    <Carousel interval={5000}>
      <Carousel.Item>
        <StaticImage
          src="../images/home-page/01-ebikes.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="eBikes"
          
        />
        <Carousel.Caption>
          <h2>Introducing Private e-bike Tours</h2>
          <p>Tailor-made to suit your interests, duration, and fitness level!</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <StaticImage
          src="../images/home-page/02-wedding-tuk-tuk.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Wedding Tuk Tuk"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/home-page/03-sunset.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Sunset over Rarotonga"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/home-page/04-tuk-tuks.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Tuk Tuks"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/home-page/05-beach-tuk-tuks.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Tuk Tuks"
          
        />
      </Carousel.Item>

      <Carousel.Item>
        <StaticImage
          src="../images/home-page/06-group-of-ebike-riders.jpg"
          quality={95}
          aspectRatio={2}
          formats={["auto", "webp", "avif"]}
          alt="Tuk Tuks"
          
        />
      </Carousel.Item>
      
    </Carousel>
    <div className="hawaiianBG d-flex justify-content-center py-3">
      <h1>Kia Orana</h1>
    </div>
    <Container className="p-4">
    <Row className="g-5">
      <Col lg={8}>
        <p><strong>Welcome to the Cook Islands!</strong> There’s no better way to explore Rarotonga than on one of our colourful and environmentally friendly electric Tuk Tuks or e-bikes.</p>
        <p>
          We offer a wide range of services that includes private tours, circle-island tours, e-bike hire, wedding limousines, transfers, and of course our famous sunset cocktail happy hour hop.
        </p>
        <p>
          Were 100% electric, 100% fun, and 100% safe.  There is nowhere else in the Pacific Islands where you can experience the open air and silence of an electric vehicle that lets you immerse yourself in the tropical surroundings while your local guide shares the hidden secrets and magic of our little piece of paradise.
        </p>
        <p>
          Feel like exploring on your own?  Circumnavigate the island at your own pace on one of our e-bikes.  You’ll comfortably make it around the island while weaving between the main road and back road and stopping at various points of interest along the way.
        </p>
        <p>
          We’re pretty sure you will have a lot of fun with us…just check out our reviews on Trip Advisor and Facebook.
        </p>
        <p>
          If you’re coming to visit Rarotonga or have an upcoming event on the island, let us make it a little more special for you in our earth friendly modes of transport.  Drop us an email or message us and we’ll make sure this is the most fun you will have on two and three wheels.
        </p>
        <p>Meitaki Ma’ata</p>
        <Row>
          <Col lg={6}>
            <p>
              <StaticImage
                src="../images/tuk-tuk-01.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="Tuk Tuk"
              />
            </p>
          </Col>
          <Col lg={6}>
            <p>
              <StaticImage
                src="../images/ebikes.jpg"
                quality={95}
                formats={["auto", "webp", "avif"]}
                alt="eBikes"
              />
            </p>
          </Col>
          
        </Row>
      </Col>
      <Col lg={4}>
        <p className="lead">Recent Press</p>
        <p>
          <a href="http://www.nzherald.co.nz/travel/news/article.cfm?c_id=7&objectid=11731227">
            <StaticImage
              src="../images/nz-herald.png"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="New Zealand Herald Logo"
            />
          </a>
        </p>
        <p>
          <a href="https://www.usatoday.com/story/travel/destinations/2017/04/01/cook-islands/99882942/">
            <StaticImage
              src="../images/large_USAToday_Logo_Square.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="USA Today"
            />
          </a>
        </p>
        <p>
          <a href="https://kiwiliving.nz/holidays/mike-in-rarotonga">
            <StaticImage
              src="../images/kiwi-living.jpg"
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Kiwi Living"
            />
          </a>
        </p>
        
      </Col>
    </Row>
    
    </Container>
      
  </Layout>
)

export default IndexPage
